// Function to scroll to an element with an offset
export const scrollIntoViewWithOffset = (element, offset) => {
  window.scrollTo({
    behavior: 'smooth',
    top:
      element.getBoundingClientRect().top -
      document.body.getBoundingClientRect().top -
      offset
  })
}
